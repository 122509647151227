.navbar {
    background: hsla(110, 90%, 41%, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: .5rem 2.5rem;

     */
    position: absolute;
    z-index: 1;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border: 2px groove black;
}

.navbar-sticky {
    background: hsla(110, 90%, 41%, .2);
    position: fixed;
    left: 50%;
    margin-left: -40vw;
    box-shadow: 1px 1px 1px #222;
    animation: moveDown .5s ease-in-out;
}

.navbar--logo-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar--logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--link {
    display: flex;
    list-style: none;
}

.navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
}

@keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }
    to {
        transform: translateY(0rem);
    }
}

@keyframes rotate {
    0% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}